<app-loading [loading]="loading" [showLogo]="true" [totalPage]="true"></app-loading>

<div [hidden]="loading" class="mainContainer" [ngClass]="{'discountLine': discountLine}">
  <app-header (onDiscountLine)="discountLine = false"></app-header>

  <div class="routerOutletWrapper" style="min-height:100vh;">
    <router-outlet #routerOutlet="outlet"></router-outlet>
  </div>
  <notifier-container style="position: absolute; left: -99999px"></notifier-container>

  <app-footer></app-footer>
</div>

<app-live-chat></app-live-chat>