import { Injectable, PLATFORM_ID, Inject } from "@angular/core";
import { WebService } from "./web.service";
import { Router } from "@angular/router";
import { isPlatformBrowser } from "@angular/common";

@Injectable({
  providedIn: "root",
})
export class SharedService {
  fanArt = false;
  showLiveChat = true;

  affilateDiscount = {
    name: "",
    boostDiscount: 1,
    otherDiscount: 1,
    affilate: null,
  };

  public EURtoUSD;
  public USDtoEUR;

  modalData = JSON.parse(sessionStorage.getItem("modalData")) || null;
  orderDetails = JSON.parse(sessionStorage.getItem("orderDetails")) || null;
  detailsForm = JSON.parse(sessionStorage.getItem("detailsForm")) || null;
  billingForm = JSON.parse(sessionStorage.getItem("billingForm")) || {};

  boostersFilter = null;

  constructor(
    private webService: WebService,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.getEURtoUSD();
  }

  getUSDtoEUR() {
    return new Promise((resolve) => {
      if (!this.USDtoEUR) {
        this.webService.getAPI("/getExchangeRate/", "USD/EUR").subscribe((resp) => {
          this.USDtoEUR = parseFloat(resp.rate);
          resolve(this.USDtoEUR);
        });
      } else {
        resolve(this.USDtoEUR);
      }
    });
  }

  getEURtoUSD() {
    return new Promise((resolve) => {
      if (!this.EURtoUSD) {
        this.webService.getAPI("/getExchangeRate/", "EUR/USD").subscribe((resp) => {
          this.EURtoUSD = parseFloat(resp.rate);
          resolve(this.EURtoUSD);
        });
      } else {
        resolve(this.EURtoUSD);
      }
    });
  }

  getAffilate() { }
  getAffilateDiscount(service) {
    return new Promise((resolve) => {
      setTimeout(() => {
        if (isPlatformBrowser(this.platformId)) {
          if (
            sessionStorage.getItem("partner") &&
            this.affilateDiscount.affilate === null
          ) {
            this.webService
              .postAPI("/user/getAffilateDiscount", {
                partner: sessionStorage.getItem("partner"),
              })
              .subscribe((res) => {
                if (res.affilate !== null) {
                  this.affilateDiscount = res;
                  if ([12, 13, 15].includes(service)) {
                    resolve(this.affilateDiscount.otherDiscount);
                  } else {
                    resolve(this.affilateDiscount.boostDiscount);
                  }
                } else {
                  resolve(1);
                }
              });
          } else {
            if (this.affilateDiscount.affilate !== null) {
              if ([12, 13, 15].includes(service)) {
                resolve(this.affilateDiscount.otherDiscount);
              } else {
                resolve(this.affilateDiscount.boostDiscount);
              }
            } else {
              resolve(1);
            }
          }
        } else {
          resolve(1);
        }
      });
    });
  }
}
