import { Component, Input, NgModule, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: "app-loading",
  template: `
    <div class="app-loading flex flex-col items-center z-[99999999] {{!totalPage ? 'relative justify-center h-screen' : ''}}" [ngClass]="{'fixed justify-center inset-0 bg-black/70': totalPage}" [hidden]="!loading">
    <div class="pulse">
      <img
        width="50" height="50"
        src="/assets/images/new/logo-icon.svg?v=3"
        class="logo" alt="Loading" [hidden]="!showLogo"/>
    </div>
    <div class="lds-ellipsis inline-block relative h-16 w-16">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
  `,
  styles: [`
    .app-loading .pulse img{
      animation: pulse 2s ease-in-out infinite;
    }
    @keyframes pulse {
      0% {
        transform: scale(1, 1);
        opacity: 1;
      }
      50% {
        transform: scale(0.9, 0.9);
        opacity: 0.3;
      }
      100% {
        transform: scale(1, 1);
        opacity: 1;
      }
    }
    .lds-ellipsis div {
      position: absolute;
      top: 27px;
      width: 11px;
      height: 11px;
      border-radius: 50%;
      background: var(--primary-gradient);
      animation-timing-function: cubic-bezier(0, 1, 1, 0);
    }
    .lds-ellipsis div:nth-child(1) {
      left: 6px;
      animation: lds-ellipsis1 0.6s infinite;
    }
    .lds-ellipsis div:nth-child(2) {
      left: 6px;
      animation: lds-ellipsis2 0.6s infinite;
    }
    .lds-ellipsis div:nth-child(3) {
      left: 26px;
      animation: lds-ellipsis2 0.6s infinite;
    }
    .lds-ellipsis div:nth-child(4) {
      left: 45px;
      animation: lds-ellipsis3 0.6s infinite;
    }
    @keyframes lds-ellipsis1 {
      0% {
        transform: scale(0);
      }
      100% {
        transform: scale(1);
      }
    }
    @keyframes lds-ellipsis3 {
      0% {
        transform: scale(1);
      }
      100% {
        transform: scale(0);
      }
    }
    @keyframes lds-ellipsis2 {
      0% {
        transform: translate(0, 0);
      }
      100% {
        transform: translate(19px, 0);
      }
    }
  `]
})
export class LoadingComponent implements OnInit {
  @Input() loading = false;
  @Input() showLogo = false;
  @Input() fullPage = false;
  @Input() totalPage = false;

  constructor(
  ) { }

  ngOnInit(): void {
  }
}

@NgModule({
  declarations: [LoadingComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule
  ],
  exports: [LoadingComponent]
})
export class LoadingModule { }
