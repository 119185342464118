import { Component, OnInit, Type } from "@angular/core";
import { NgxSmartModalService } from "ngx-smart-modal";
import { environment } from "../../../../../environments/environment";
import { AuthService } from "../../../../service/auth.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-members-area",
  templateUrl: "./members-area.component.html",
  styleUrls: ["./members-area.component.scss"]
})
export class MembersAreaComponent implements OnInit {
  public globals = environment;
  loginDropdownToggle = false;

  constructor(
    private modalService: NgxSmartModalService,
    public auth: AuthService,
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  login() {
    if (["/login"].includes(
      this.router.url.split("?")[0]
    )) {
      this.modalService.open('loginModal');
    } else {
      this.router.navigate(["/login"]);
    }
  }

  public goToDashboard() {
    window.open(
      this.globals.dashboard + "/loggedIn/" + localStorage.getItem("token"),
      "_self"
    );
  }
}
