import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class WebService {
  BASE_URL = environment.apiServer;

  constructor(private http: HttpClient) { }

  getAPI(endpoint, data = ""){
    return this.http.get<any>(this.BASE_URL + endpoint + data);
  };
  postAPI(endpoint, data){
    return this.http.post<any>(
      this.BASE_URL + endpoint,
      data
    );
  };

  private handleError(error) {
    //this.sb.open(error, 'close', { duration: 2000 });
  }
}
