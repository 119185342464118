import { Injectable, Inject, PLATFORM_ID } from "@angular/core";
import { ActivatedRoute, ActivatedRouteSnapshot, CanActivate, Router } from "@angular/router";
import { AuthService } from "../../../service/auth.service";
import { WebService } from "../../../service/web.service";
import { SharedService } from "../../../service/shared.service";
import { NotifierService } from "angular-notifier";

@Injectable({
  providedIn: 'root',
})
export class PayOrderGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private webService: WebService,
    private sharedService: SharedService,
    private router: Router,
    private notifyService: NotifierService
  ) {}

  canActivate(route: ActivatedRouteSnapshot) {
    const __this = this;
    return this.getOrder(route).then(function (res) {
      if(res){
        return true;
      }else{
        __this.router.navigateByUrl("/");
        alert("Order not available anymore, please try creating a new order.");
        return false;
      }
    });
  }

  getOrder(route){
    return new Promise((resolve) => {
        this.webService.postAPI("/order/payAwatingOrder", {id: route.params["orderId"], access_token: localStorage.getItem("token")}).subscribe(res=>{
          if(res){
            sessionStorage.setItem("modalData", JSON.stringify(res.data));
            this.sharedService.modalData = res.data;
            this.sharedService.orderDetails = null;
            resolve(true);
          }else{
            resolve(false);
          }
      });
    });
  }
}
