import { NgModule, ModuleWithProviders } from "@angular/core";
import { Routes, RouterModule, PreloadAllModules } from "@angular/router";

export const appRoutes = [
  {
    path: "",
    loadChildren: () =>
      import("./home/home.module").then((m) => m.HomeModule),
  },
  {
    path: "elo-boost-services",
    loadChildren: () =>
      import("./services/services.module").then((m) => m.ServicesModule),
  },
  {
    path: "terms-of-use",
    loadChildren: () =>
      import("./pages/terms/terms.module").then((m) => m.TermsModule),
  },
  {
    path: "privacy-policy",
    loadChildren: () =>
      import("./pages/terms/terms.module").then((m) => m.TermsModule),
  },
  {
    path: "dmca",
    loadChildren: () =>
      import("./pages/terms/terms.module").then((m) => m.TermsModule),
  },
  {
    path: "boosters",
    loadChildren: () =>
      import("./pages/boosters/boosters/boosters.module").then(
        (m) => m.BoostersModule
      ),
  },
  {
    path: "employee/application",
    loadChildren: () =>
      import("./pages/boosters/application/application.module").then(
        (m) => m.ApplicationModule
      ),
  },
  {
    path: "employee/seller-application",
    loadChildren: () =>
      import("./pages/seller-application/seller-application.module").then(
        (m) => m.SellerApplicationModule
      ),
  },
  {
    path: "coaching",
    loadChildren: () =>
      import("./services/coaching/coaching/coaching.module").then(
        (m) => m.CoachingModule
      ),
  },
  {
    path: "contact",
    loadChildren: () =>
      import("./pages/contact/contact.module").then((m) => m.ContactModule),
  },
  {
    path: "pay-with-mobile",
    loadChildren: () =>
      import("./pages/mobilepayment/mobilepayment.module").then(
        (m) => m.MobilepaymentModule
      ),
  },
  {
    path: "loyalty-program",
    loadChildren: () =>
      import("./pages/loyalty-program/loyalty-program.module").then(
        (m) => m.LoyaltyProgramModule
      )
  },
  {
    path: "partner-program",
    loadChildren: () =>
      import("./pages/affilate-program/affilate-program.module").then(
        (m) => m.AffilateProgramModule
      ),
  },
  {
    path: "boostroyal-premium",
    loadChildren: () =>
      import("./pages/br-premium/br-premium.module").then(
        (m) => m.BrPremiumModule
      ),
  },
  {
    path: "blog",
    loadChildren: () =>
      import("./pages/blog/blog/blog.module").then((m) => m.BlogModule),
  },
  {
    path: "faq",
    loadChildren: () =>
      import("./pages/contact/contact.module").then((m) => m.ContactModule),
  },
  {
    path: "checkout/pay/:link",
    loadChildren: () =>
      import("./checkout/payment-page/payment-page.module").then(
        (m) => m.PaymentpageModule
      ),
  },
  {
    path: "checkout/complete",
    loadChildren: () =>
      import("./checkout/payment-complete/payment-complete.module").then(
        (m) => m.PaymentcompleteModule
      ),
  },
  {
    path: "checkout/payment",
    loadChildren: () =>
      import("./checkout/checkout/pay/pay.module").then(
        (m) => m.CheckoutPayModule
      ),
  },
  {
    path: "feedback/:token",
    loadChildren: () =>
      import("./pages/feedback/feedback.module").then((m) => m.FeedbackModule),
  },
  // SERVICES
  {
    path: "buy-league-of-legends-account",
    loadChildren: () =>
      import("./services/account-separator/account-separator.module").then(
        (m) => m.AccountSeparatorModule
      ),
  },
  {
    path: "tft-ranked-boost",
    loadChildren: () =>
      import("./services/teamfight-tactics/teamfight-tactics.module").then(
        (m) => m.TeamfightTacticsModule
      )
  },
  {
    path: "lor-boosting",
    loadChildren: () =>
      import("./services/lor-boosting/lor-boosting.module").then(
        (m) => m.LorBoostingModule
      )
  },
  {
    path: "eternals-boosting",
    loadChildren: () =>
      import("./services/eternals-boosting/eternals-boosting.module").then(
        (m) => m.EternalsBoostingModule
      )
  },
  {
    path: "clash-boosting",
    loadChildren: () =>
      import("./services/clash-boosting/clash-boosting.module").then(
        (m) => m.ClashBoostingModule
      )
  },
  {
    path: "valorant-boosting",
    loadChildren: () =>
      import("./services/valorant-boosting/valorant-boosting.module").then(
        (m) => m.ValorantBoostingModule
      )
  },
  {
    path: "wild-rift-boosting",
    loadChildren: () =>
      import("./services/wild-rift/wild-rift.module").then(
        (m) => m.WildRiftModule
      )
  },
  {
    path: "ranked-wins-boost",
    loadChildren: () =>
      import("./services/ranked-wins-boost/ranked-wins-boost.module").then(
        (m) => m.RankedwinsboostModule
      )
  },
  {
    path: "lol-boosting",
    loadChildren: () =>
      import("./services/league-boosting/league-boosting.module").then(
        (m) => m.LeagueboostingModule
      )
  },
  {
    path: "duoqueue-boosting",
    loadChildren: () =>
      import("./services/duoq-boosting/duoq-boosting.module").then(
        (m) => m.DuoqboostingModule
      )
  },
  {
    path: "placement-matches",
    loadChildren: () =>
      import("./services/placement-matches/placement-matches.module").then(
        (m) => m.PlacementmatchesModule
      )
  },
  {
    path: "normal-matches",
    loadChildren: () =>
      import("./services/normal-matches/normal-matches.module").then(
        (m) => m.NormalmatchesModule
      )
  },
  {
    path: "champion-mastery",
    loadChildren: () =>
      import("./services/champion-mastery/champion-mastery.module").then(
        (m) => m.ChampionmasteryModule
      )
  },
  {
    path: "promotion-boosting",
    loadChildren: () =>
      import("./services/promo-boosting/promo-boosting.module").then(
        (m) => m.PromoboostingModule
      )
  },
  {
    path: "account-leveling",
    loadChildren: () =>
      import("./services/account-leveling/account-leveling.module").then(
        (m) => m.AccountlevelingModule
      )
  },
  {
    path: "elo-boosting-packs",
    redirectTo: '/lol-boosting',
  },
  {
    path: "buy-smurf-account",
    loadChildren: () =>
      import("./services/smurf-accounts/smurf-accounts.module").then(
        (m) => m.SmurfaccountsModule
      ),
  },
  {
    path: "wild-rift-smurf",
    data: { game: 'wildrift' },
    loadChildren: () =>
      import("./services/smurf-accounts/smurf-accounts.module").then(
        (m) => m.SmurfaccountsModule
      ),
  },
  {
    path: "valorant-smurf",
    data: { game: 'valorant' },
    loadChildren: () =>
      import("./services/smurf-accounts/smurf-accounts.module").then(
        (m) => m.SmurfaccountsModule
      ),
  },
  {
    path: "buy-lol-account",
    loadChildren: () =>
      import(
        "./services/account-market/account-market/account-market.module"
      ).then((m) => m.AccountmarketModule),
  },
  {
    path: "clash-boosting",
    loadChildren: () =>
      import("./services/clash-boosting/clash-boosting.module").then(
        (m) => m.ClashBoostingModule
      ),
  },
  {
    path: "smurf/:id",
    loadChildren: () =>
      import(
        "./services/account-market/account-market-single/account-market-single.module"
      ).then((m) => m.AccountmarketSingleModule),
  },

  // GERMAN
  {
    path: "league-of-legends-konto-kaufen",
    loadChildren: () =>
      import("./services/account-separator/account-separator.module").then(
        (m) => m.AccountSeparatorModule
      ),
  },
  {
    path: "elo-boost-dienste",
    loadChildren: () =>
      import("./services/services.module").then((m) => m.ServicesModule),
  },
  {
    path: "ranglistensiegboost",
    loadChildren: () =>
      import("./services/ranked-wins-boost/ranked-wins-boost.module").then(
        (m) => m.RankedwinsboostModule
      )
  },
  {
    path: "platzierungsspiele",
    loadChildren: () =>
      import("./services/placement-matches/placement-matches.module").then(
        (m) => m.PlacementmatchesModule
      )
  },
  {
    path: "normale-spiele",
    loadChildren: () =>
      import("./services/normal-matches/normal-matches.module").then(
        (m) => m.NormalmatchesModule
      )
  },
  {
    path: "championmeisterschaft",
    loadChildren: () =>
      import("./services/champion-mastery/champion-mastery.module").then(
        (m) => m.ChampionmasteryModule
      )
  },
  {
    path: "elo-boosting-pakete",
    redirectTo: '/lol-boosting',
  },
  {
    path: "unrangiertes-smurf-kaufen",
    loadChildren: () =>
      import("./services/smurf-accounts/smurf-accounts.module").then(
        (m) => m.SmurfaccountsModule
      ),
  },
  {
    path: "lol-kontoverkauf",
    loadChildren: () =>
      import(
        "./services/account-market/account-market/account-market.module"
      ).then((m) => m.AccountmarketModule),
  },

  // FRENCH
  {
    path: "acheter-un-compte-league-of-legends",
    loadChildren: () =>
      import("./services/account-separator/account-separator.module").then(
        (m) => m.AccountSeparatorModule
      ),
  },
  {
    path: "services-de-elo-boost",
    loadChildren: () =>
      import("./services/services.module").then((m) => m.ServicesModule),
  },
  {
    path: "boost-de-victoires-en-classees",
    loadChildren: () =>
      import("./services/ranked-wins-boost/ranked-wins-boost.module").then(
        (m) => m.RankedwinsboostModule
      )
  },
  {
    path: "boost-de-duo-queue",
    loadChildren: () =>
      import("./services/duoq-boosting/duoq-boosting.module").then(
        (m) => m.DuoqboostingModule
      )
  },
  {
    path: "matchs-de-placement",
    loadChildren: () =>
      import("./services/placement-matches/placement-matches.module").then(
        (m) => m.PlacementmatchesModule
      )
  },
  {
    path: "parties-normales",
    loadChildren: () =>
      import("./services/normal-matches/normal-matches.module").then(
        (m) => m.NormalmatchesModule
      )
  },
  {
    path: "maitrise-du-champion",
    loadChildren: () =>
      import("./services/champion-mastery/champion-mastery.module").then(
        (m) => m.ChampionmasteryModule
      )
  },
  {
    path: "les-packs-de-boost-elo",
    redirectTo: '/lol-boosting',
  },
  {
    path: "achetez-un-smurf",
    loadChildren: () =>
      import("./services/smurf-accounts/smurf-accounts.module").then(
        (m) => m.SmurfaccountsModule
      ),
  },
  {
    path: "lol-compte-vente",
    loadChildren: () =>
      import(
        "./services/account-market/account-market/account-market.module"
      ).then((m) => m.AccountmarketModule),
  },

  // SPANISH
  {
    path: "compre-league-of-legends-account",
    loadChildren: () =>
      import("./services/account-separator/account-separator.module").then(
        (m) => m.AccountSeparatorModule
      ),
  },
  {
    path: "servicios-de-elo-boost",
    loadChildren: () =>
      import("./services/services.module").then((m) => m.ServicesModule),
  },
  {
    path: "entrenamiento-de-league-of-legends",
    loadChildren: () =>
      import("./services/coaching/coaching/coaching.module").then(
        (m) => m.CoachingModule
      ),
  },
  {
    path: "boost-de-victorias-en-partidas-ranked",
    loadChildren: () =>
      import("./services/ranked-wins-boost/ranked-wins-boost.module").then(
        (m) => m.RankedwinsboostModule
      )
  },
  {
    path: "boost-de-cola-de-emparejamiento-duo",
    loadChildren: () =>
      import("./services/duoq-boosting/duoq-boosting.module").then(
        (m) => m.DuoqboostingModule
      )
  },
  {
    path: "partidas-de-posicionamiento",
    loadChildren: () =>
      import("./services/placement-matches/placement-matches.module").then(
        (m) => m.PlacementmatchesModule
      )
  },
  {
    path: "partidas-normales",
    loadChildren: () =>
      import("./services/normal-matches/normal-matches.module").then(
        (m) => m.NormalmatchesModule
      )
  },
  {
    path: "maestria-de-campeon",
    loadChildren: () =>
      import("./services/champion-mastery/champion-mastery.module").then(
        (m) => m.ChampionmasteryModule
      )
  },
  {
    path: "paquetes-de-elo-boosting",
    redirectTo: '/lol-boosting',
  },
  {
    path: "compre-unranked-smurf",
    loadChildren: () =>
      import("./services/smurf-accounts/smurf-accounts.module").then(
        (m) => m.SmurfaccountsModule
      ),
  },
  {
    path: "lol-vendiendo-cuentas",
    loadChildren: () =>
      import(
        "./services/account-market/account-market/account-market.module"
      ).then((m) => m.AccountmarketModule),
  },

  // ITALIAN
  {
    path: "compra-league-of-legends-account",
    loadChildren: () =>
      import("./services/account-separator/account-separator.module").then(
        (m) => m.AccountSeparatorModule
      ),
  },
  {
    path: "servizi-elo-boost",
    loadChildren: () =>
      import("./services/services.module").then((m) => m.ServicesModule),
  },
  {
    path: "allenamento",
    loadChildren: () =>
      import("./services/coaching/coaching/coaching.module").then(
        (m) => m.CoachingModule
      ),
  },
  {
    path: "partite-di-collocamento",
    loadChildren: () =>
      import("./services/placement-matches/placement-matches.module").then(
        (m) => m.PlacementmatchesModule
      )
  },
  {
    path: "partite-normali",
    loadChildren: () =>
      import("./services/normal-matches/normal-matches.module").then(
        (m) => m.NormalmatchesModule
      )
  },
  {
    path: "maestrie-dei-campioni",
    loadChildren: () =>
      import("./services/champion-mastery/champion-mastery.module").then(
        (m) => m.ChampionmasteryModule
      )
  },
  {
    path: "compra-unranked-smurf",
    loadChildren: () =>
      import("./services/smurf-accounts/smurf-accounts.module").then(
        (m) => m.SmurfaccountsModule
      ),
  },
  {
    path: "lol-vendita-account",
    loadChildren: () =>
      import(
        "./services/account-market/account-market/account-market.module"
      ).then((m) => m.AccountmarketModule),
  },

  // DUTCH
  {
    path: "koop-league-of-legends-account",
    loadChildren: () =>
      import("./services/account-separator/account-separator.module").then(
        (m) => m.AccountSeparatorModule
      ),
  },
  {
    path: "koop-smurf-account",
    loadChildren: () =>
      import("./services/smurf-accounts/smurf-accounts.module").then(
        (m) => m.SmurfaccountsModule
      ),
  },
  {
    path: "lol-account-kopen",
    loadChildren: () =>
      import(
        "./services/account-market/account-market/account-market.module"
      ).then((m) => m.AccountmarketModule),
  },

  // SWEDISH
  {
    path: "kop-liga-av-legender-konto",
    loadChildren: () =>
      import("./services/account-separator/account-separator.module").then(
        (m) => m.AccountSeparatorModule
      ),
  },
  {
    path: "elo-boost-tjanster",
    loadChildren: () =>
      import("./services/services.module").then((m) => m.ServicesModule),
  },
  {
    path: "rang-wins-boost",
    loadChildren: () =>
      import("./services/ranked-wins-boost/ranked-wins-boost.module").then(
        (m) => m.RankedwinsboostModule
      )
  },
  {
    path: "placerings-matcher",
    loadChildren: () =>
      import("./services/placement-matches/placement-matches.module").then(
        (m) => m.PlacementmatchesModule
      )
  },
  {
    path: "normala-matcher",
    loadChildren: () =>
      import("./services/normal-matches/normal-matches.module").then(
        (m) => m.NormalmatchesModule
      )
  },
  {
    path: "mastare-beharskning",
    loadChildren: () =>
      import("./services/champion-mastery/champion-mastery.module").then(
        (m) => m.ChampionmasteryModule
      )
  },
  {
    path: "elo-öka-packs",
    redirectTo: '/lol-boosting',
  },
  {
    path: "buy-smurf-konto",
    loadChildren: () =>
      import("./services/smurf-accounts/smurf-accounts.module").then(
        (m) => m.SmurfaccountsModule
      ),
  },
  {
    path: "buy-lol-konto",
    loadChildren: () =>
      import(
        "./services/account-market/account-market/account-market.module"
      ).then((m) => m.AccountmarketModule),
  },
  {
    path: "konto-utjamning",
    loadChildren: () =>
      import("./services/account-leveling/account-leveling.module").then(
        (m) => m.AccountlevelingModule
      )
  },
  {
    path: "tft-rankade-boost",
    loadChildren: () =>
      import("./services/teamfight-tactics/teamfight-tactics.module").then(
        (m) => m.TeamfightTacticsModule
      )
  },

  // NORWEGIAN
  {
    path: "kjop-liga-av-legender-konto",
    loadChildren: () =>
      import("./services/account-separator/account-separator.module").then(
        (m) => m.AccountSeparatorModule
      ),
  },
  {
    path: "elo-boost-tjenester",
    loadChildren: () =>
      import("./services/services.module").then((m) => m.ServicesModule),
  },
  {
    path: "rangerte-vinner-boost",
    loadChildren: () =>
      import("./services/ranked-wins-boost/ranked-wins-boost.module").then(
        (m) => m.RankedwinsboostModule
      )
  },
  {
    path: "duoqueue-boosting",
    loadChildren: () =>
      import("./services/duoq-boosting/duoq-boosting.module").then(
        (m) => m.DuoqboostingModule
      )
  },
  {
    path: "plasserings-matcher",
    loadChildren: () =>
      import("./services/placement-matches/placement-matches.module").then(
        (m) => m.PlacementmatchesModule
      )
  },
  {
    path: "normale-matcher",
    loadChildren: () =>
      import("./services/normal-matches/normal-matches.module").then(
        (m) => m.NormalmatchesModule
      )
  },
  {
    path: "elo-oke-pakker",
    redirectTo: '/lol-boosting',
  },
  {
    path: "kjop-smurf-account",
    loadChildren: () =>
      import("./services/smurf-accounts/smurf-accounts.module").then(
        (m) => m.SmurfaccountsModule
      ),
  },
  {
    path: "kjop-lol-account",
    loadChildren: () =>
      import(
        "./services/account-market/account-market/account-market.module"
      ).then((m) => m.AccountmarketModule),
  },
  {
    path: "konto-utjevning",
    loadChildren: () =>
      import("./services/account-leveling/account-leveling.module").then(
        (m) => m.AccountlevelingModule
      )
  },
  {
    path: "tft-rangert-boost",
    loadChildren: () =>
      import("./services/teamfight-tactics/teamfight-tactics.module").then(
        (m) => m.TeamfightTacticsModule
      )
  },

  // DANISH
  {
    path: "kob-league-of-legends-account",
    loadChildren: () =>
      import("./services/account-separator/account-separator.module").then(
        (m) => m.AccountSeparatorModule
      ),
  },
  {
    path: "elo-boosting-pakker",
    redirectTo: '/lol-boosting',
  },
  {
    path: "kob-smurf-account",
    loadChildren: () =>
      import("./services/smurf-accounts/smurf-accounts.module").then(
        (m) => m.SmurfaccountsModule
      ),
  },
  {
    path: "kob-lol-account",
    loadChildren: () =>
      import(
        "./services/account-market/account-market/account-market.module"
      ).then((m) => m.AccountmarketModule),
  },
  {
    path: "404",
    loadChildren: () =>
      import(
        "./pages/error404/error404.module"
      ).then((m) => m.Error404Module),
  },
  { path: "**", redirectTo: "/404" },
];

export const AppRouting: ModuleWithProviders<RouterModule> = RouterModule.forRoot(
  appRoutes,
  {
    anchorScrolling: 'enabled',
    relativeLinkResolution: 'legacy',
    initialNavigation: 'enabled'
  }
);
