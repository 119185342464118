<footer class="bg-deep-purple-1 mt-20">
  <div class="container py-10">
    <img class="mb-12 w-full" [lazyLoad]="'/assets/images/new/payment-icons.svg'">
    <div class="mb-8 flex gap-3 md:items-center" >
      <img [lazyLoad]="'/assets/images/new/logo-icon.svg'" width="66px" height="66px">
      <img class="hidden md:block mt-1" [lazyLoad]="'/assets/images/new/logo-text.svg'" height="66px">
    </div>
    <div class="flex gap-10 justify-between flex-wrap md:gap-5">
      <div class="font-light leading-normal text-base max-w-2xl" i18n="@@footer.trademark">BoostRoyal isn’t endorsed by Riot Games and doesn’t reflect the views
        or
        opinions of Riot Games or anyone officially
        involved in producing or managing League of Legends. League of Legends and Riot Games are trademarks or
        registered
        trademarks of Riot Games, Inc. League of Legends © Riot Games, Inc.
      </div>

      <div class="md:order-3">
        <div class="font-bold text-xl mb-6 text-yellow">Legal</div>

        <ul class="shrink-0 flex flex-col gap-3">
          <li class="duration-500 hover:text-yellow">
            <a routerLink="/terms-of-use" title="Terms of use">Terms of use</a>
          </li>
          <li class="duration-500 hover:text-yellow">
            <a routerLink="/privacy-policy" title="Privacy Policy">Privacy policy</a>
          </li>
          <li class="duration-500 hover:text-yellow">
            <a routerLink="/dmca" title="DMCA Notice">DMCA Notice</a>
          </li>
          <li class="duration-500 hover:text-yellow">
            <a routerLink="/partner-program" title="Partnership">Partnership</a>
          </li>
          <li class="duration-500 hover:text-yellow">
            <a href="javascript:void(0)" (click)="loadNewsletter()" title="Newsletter">Newsletter</a>
          </li>
          <li>
            <app-currency-selector></app-currency-selector>
          </li>
        </ul>
      </div>
      
      <div class="flex gap-4 shrink-0 2xl:order-2 2xl:shrink 2xl:basis-full 2xl:justify-center">
        <a href="http://facebook.com/boostroyal" title="BoostRoyal Facebook" target="_blank" rel="noreferer, ,noopener">
          <img alt="boostroyal facebook" [lazyLoad]="'/assets/icons/facebook.svg'" width="22px">
        </a>
        <a href="https://www.instagram.com/boostroyalofficial" title="BoostRoyal Instagram" target="_blank" rel="noreferer, ,noopener">
          <img alt="boostroyal facebook" [lazyLoad]="'/assets/icons/instagram.svg'" width="22px">
        </a>
        <a href="http://twitter.com/boost_royal" title="BoostRoyal Twitter" target="_blank" rel="noreferer, ,noopener">
          <img alt="boostroyal facebook" [lazyLoad]="'/assets/icons/twitter.svg'" width="22px">
        </a>
        <a href="skype:boostroyal?chat" title="BoostRoyal Skype">
          <img alt="boostroyal facebook" [lazyLoad]="'/assets/icons/skype.svg'" width="22px">
        </a>
      </div>
    
      <div class="md:order-4">
        <div class="font-bold text-xl mb-6 text-yellow">More games</div>
        <ul class="shrink-0 flex flex-col gap-3">
          <li class="duration-500 hover:text-yellow">
            <a href="https://buyboosting.com/dota2-boosting" target="_blank" rel="noreferer, ,noopener">Dota 2 Boosting</a>
          </li>
          <li class="duration-500 hover:text-yellow">
            <a href="https://buyboosting.com/apex-boosting" target="_blank" rel="noreferer, ,noopener">Apex Boosting</a>
          </li>
          <li class="duration-500 hover:text-yellow">
            <a href="https://buyboosting.com/rl-boosting" target="_blank" rel="noreferer, ,noopener">Rocket League Boosting</a>
          </li>
          <li class="duration-500 hover:text-yellow">
            <a href="https://buyboosting.com/rss-boosting" target="_blank" rel="noreferer, ,noopener">Rainbow Six Boosting</a>
          </li>
          <li class="duration-500 hover:text-yellow">
            <a href="https://buyboosting.com/csgo-boosting" target="_blank" rel="noreferer, ,noopener">CS:GO Boosting</a>
          </li>
          <li class="duration-500 hover:text-yellow">
            <a href="https://igitems.com/" target="_blank" rel="noreferer, ,noopener">igitems</a>
          </li>
        </ul>
      </div>
    </div>
    <div class="font-light text-base mt-8">
      &copy; <span class="font-bold">2014-{{year}} BoostRoyal</span> - All Rights Reserved
    </div>
  </div>
</footer>

<div class="fixed bg-black rounded-lg p-4 left-6 bottom-6 z-50 max-w-xs" *ngIf="cookieNotice">
  <div>
    🍪 We use cookies to ensure you get the best experience on our website.
  </div>
  <div class="flex justify-center items-center gap-2 mt-4">
    <div class="btn gradient py-2" (click)="acceptCookieNotice()">Accept</div>
    <a class="underline underline-offset-1" href="/privacy-policy" target="_blank" rel="noreferer, ,noopener">Learn more</a>
  </div>
</div>

<div *ngIf="loaded">
  <ng-container *ngComponentOutlet="myComponent"></ng-container>
</div>