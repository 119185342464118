import { BreakpointObserver } from '@angular/cdk/layout';
import { isPlatformBrowser } from '@angular/common';
import { Component, EventEmitter, Inject, OnInit, Output, PLATFORM_ID } from '@angular/core';
import { interval } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-discount-line',
  templateUrl: './discount-line.component.html',
  styleUrls: ['./discount-line.component.scss'],
})
export class DiscountLineComponent implements OnInit {
  @Output() onDiscount = new EventEmitter();

  private _trialEndsAt;
  private subscription;

  public _diff: number = 1;
  private _days: number;
  private _hours: number;

  private _minutes: number;

  private _seconds: number;

  showLine = false;


  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    public breakpointObserver: BreakpointObserver,) { 
      this.onDiscount.emit(this.showLine);
    }

  ngOnInit(): void {
    this._trialEndsAt = "2022-01-10";
    if (isPlatformBrowser(this.platformId)) {
      //if (!localStorage.getItem('ftd') || (localStorage.getItem('ftd') && new Date().setHours(0, 0, 0, 0) !== new Date(+localStorage.getItem('ftd')).setHours(0, 0, 0, 0)) || this.breakpointObserver.isMatched("(max-width: 599px)")) {
        setTimeout(() => {
          this.showLine = false;
          this.onDiscount.emit(this.showLine);
          localStorage.setItem('ftd', +new Date() + "");
          if (this.showLine) {
            this.subscription = interval(1000).pipe(
              map((x) => {
                this._diff = Date.parse(this._trialEndsAt) - Date.parse(new Date().toString());
              })).subscribe((x) => {
                this._days = this.getDays(this._diff);
                this._hours = this.getHours(this._diff);
                this._minutes = this.getMinutes(this._diff);
                this._seconds = this.getSeconds(this._diff);
              });
          }
        }, 0);
      }
    //}
  }

  getDays(t) {
    return Math.floor(t / (1000 * 60 * 60 * 24));
  }

  getHours(t) {
    return Math.floor((t / (1000 * 60 * 60)) % 24);
  }

  getMinutes(t) {
    return Math.floor((t / 1000 / 60) % 60);
  }

  getSeconds(t) {
    return Math.floor((t / 1000) % 60);
  }

  ngOnDestroy(): void {
    if (this.subscription && isPlatformBrowser(this.platformId)) {
      this.subscription.unsubscribe();
    }
  }
}
