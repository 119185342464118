/*
 * -- LinkService --        [Temporary]
 * @MarkPieszak
 * Added removeTag by @DominicBoettger
 * Similar to Meta service but made to handle <link> creation for SEO purposes
 * -- NOTE: Soon there will be an overall DocumentService within Angular that handles Meta/Link everything
 */

import { Injectable, Optional, RendererFactory2, ViewEncapsulation, Inject, LOCALE_ID } from '@angular/core';
import { DOCUMENT } from "@angular/common";
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class LinkService {
  constructor(
    private rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private document,
    private router: Router,
    @Inject(LOCALE_ID) public locale: string
  ) {

  }

  /**
   * Inject the State into the bottom of the <head>
   */
  addTag(tag: LinkDefinition, forceCreation?: boolean) {

    try {
      const renderer = this.rendererFactory.createRenderer(this.document, {
        id: '-1',
        encapsulation: ViewEncapsulation.None,
        styles: [],
        data: {}
      });

      const link = renderer.createElement('link');

      const head = this.document.head;
      const selector = this._parseSelector(tag);

      if (head === null) {
        throw new Error('<head> not found within DOCUMENT.');
      }

      Object.keys(tag).forEach((prop: string) => {
        return renderer.setAttribute(link, prop, tag[prop]);
      });

      // [TODO]: get them to update the existing one (if it exists) ?
      renderer.appendChild(head, link);

    } catch (e) {
      console.error('Error within linkService : ', e);
    }
  }

  removeTag(attrSelector: string) {
    if (attrSelector) {
      try {
        const renderer = this.rendererFactory.createRenderer(this.document, {
          id: '-1',
          encapsulation: ViewEncapsulation.None,
          styles: [],
          data: {}
        });
        const head = this.document.head;
        if (head === null) {
          throw new Error('<head> not found within DOCUMENT.');
        }
        const linkTags = this.document.querySelectorAll('link[' + attrSelector + ']');
        for (const link of linkTags) {
          renderer.removeChild(head, link);
        }
      } catch (e) {
      }
    }
  }

  private _parseSelector(tag: LinkDefinition): string {
    // Possibly re-work this
    const attr: string = tag.rel ? 'rel' : 'hreflang';
    return `${attr}="${tag[attr]}"`;
  }

  getRoute(lang, routeTranslate) {
    const routes = [
      {
        path: "/elo-boost-services",
        de: "/elo-boost-dienste",
        fr: "/services-de-elo-boost",
        es: "/servicios-de-elo-boost",
        it: "/servizi-elo-boost",
        sv: "/elo-boost-tjanster",
        da: "/elo-boost-tjenester",
        nn: "/elo-boost-tjenester",
      },
      {
        path: "/buy-league-of-legends-account",
        de: "/league-of-legends-konto-kaufen",
        fr: "/acheter-un-compte-league-of-legends",
        es: "/compre-league-of-legends-account",
        it: "/compra-league-of-legends-account",
        nl: "/koop-league-of-legends-account",
        sv: "/kop-liga-av-legender-konto",
        da: "/kob-league-of-legends-account",
        nn: "/kjop-liga-av-legender-konto",
      },
      {
        path: "/ranked-wins-boost",
        de: "/ranglistensiegboost",
        fr: "/boost-de-victoires-en-classees",
        es: "/boost-de-victorias-en-partidas-ranked",
        sv: "/rang-wins-boost",
        nn: "/rangerte-vinner-boost",
      },
      {
        path: "/league-boosting",
        de: "/ligaboosting",
        fr: "/boost-de-ligue",
        es: "/boost-de-liga",
        nn: "/liga-okning",
      },
      {
        path: "/duoqueue-boosting",
        fr: "/boost-de-duo-queue",
        es: "/boost-de-cola-de-emparejamiento-duo"
      },
      {
        path: "/placement-matches",
        de: "/platzierungsspiele",
        fr: "/matchs-de-placement",
        es: "/partidas-de-posicionamiento",
        it: "/partite-di-collocamento",
        sv: "/placerings-matcher",
        nn: "/plasserings-matcher",
      },
      {
        path: "/normal-matches",
        de: "/normale-spiele",
        fr: "/parties-normales",
        es: "/partidas-normales",
        it: "/partite-normali",
        sv: "/normala-matcher",
        nn: "/normale-matcher",
      },
      {
        path: "/champion-mastery",
        de: "/championmeisterschaft",
        fr: "/maitrise-du-champion",
        es: "/maestria-de-campeon",
        it: "/maestrie-dei-campioni",
        sv: "/mastare-beharskning",
      },
      {
        path: "/elo-boosting-packs",
        de: "/elo-boosting-pakete",
        fr: "/les-packs-de-boost-elo",
        es: "/paquetes-de-elo-boosting",
        sv: "/elo-öka-packs",
        da: "/elo-boosting-pakker",
        nn: "/elo-oke-pakker",
      },
      {
        path: "/buy-smurf-account",
        de: "/unrangiertes-smurf-kaufen",
        fr: "/achetez-un-smurf",
        es: "/compre-unranked-smurf",
        it: "/compra-unranked-smurf",
        nl: "/koop-smurf-account",
        sv: "/buy-smurf-konto",
        da: "/kob-smurf-account",
        nn: "/kjop-smurf-account",
      },
      {
        path: "/buy-lol-account",
        de: "/lol-kontoverkauf",
        fr: "/lol-compte-vente",
        es: "/lol-vendiendo-cuentas",
        it: "/lol-vendita-account",
        nl: "/lol-account-kopen",
        sv: "/buy-lol-konto",
        da: "/kob-lol-account",
        nn: "/kjop-lol-account",
      },
      {
        path: "/coaching",
        es: "/entrenamiento-de-league-of-legends",
        it: "/allenamento",
      },
      {
        path: "/account-leveling",
        sv: "/konto-utjamning",
        nn: "/konto-utjevning",
      },
      {
        path: "/tft-ranked-boost",
        sv: "/tft-rankade-boost",
        nn: "/tft-rangert-boost",
      },
    ];

    let finalRoute = this.router.url;
    for (let route of routes) {
      if (!routeTranslate) {
        if (route.path === finalRoute || (Object.values(route)).includes(finalRoute)) {
          finalRoute = route.hasOwnProperty(lang) ? route[lang] : route.path;
          break;
        }
      } else {
        if (route.path === routeTranslate) {
          const currLang = this.locale.includes('-') ? this.locale.split('-')[0] : this.locale;
          finalRoute = route.hasOwnProperty(currLang) ? route[currLang] : route.path;
          break;
        }
      }
    }
    //console.log(finalRoute);
    return finalRoute;
  }
}

export declare type LinkDefinition = {
  charset?: string;
  crossorigin?: string;
  href?: string;
  hreflang?: string;
  media?: string;
  rel?: string;
  rev?: string;
  sizes?: string;
  target?: string;
  type?: string;
} & {
  [prop: string]: string;
};