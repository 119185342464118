import { Injectable, PLATFORM_ID, Inject } from "@angular/core";
import { Router } from "@angular/router";
import { environment } from "../../environments/environment";
import { HttpClient } from "@angular/common/http";
import jwt_decode from "jwt-decode";
import { isPlatformBrowser } from "@angular/common";
import { CookieService } from "ngx-cookie-service";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  BASE_URL = environment.apiServer + "/auth";
  NAME_KEY = "name";
  TOKEN_KEY = "token";

  public user_id = 0;
  public role = 3;
  public email = "";
  public name = "";
  public sanitized_name = "";
  public image;
  public authenticated = false;
  public employee;

  public loyaltyRank = null;
  public newNames = {
    Tier1: "Novice",
    Tier2: "Advanced",
    Tier3: "Veteran",
    Tier4: "Ultimate",
  };
  public baseDiscount = 1;
  public baseCashback = 1;

  coinBalance = null;
  premiumUser = false;

  userCountry = null;
  primaryCurrency = 'EUR';
  primaryServer = 1;

  online = null;

  constructor(
    private http: HttpClient,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: Object,
    private cookieService: CookieService
  ) {
    if (isPlatformBrowser(this.platformId)) {
      this.firstAuth().then((rs) => {
        if (rs) {
          this.getUserInfo();
        }
      });
      this.getGeoInfo();

      if (!this.online) {
        this.http
          .get<any>(this.BASE_URL + "/onlinePresence")
          .toPromise()
          .then((res) => {
            this.online = res.online
          })
          .catch();
      }
    }
  }

  getGeoInfo() {
    return new Promise((resolve) => {
      if (!this.cookieService.get('geo')) {
        this.http
          .get<any>(environment.apiServer + "/user/getCountry")
          .toPromise()
          .then((res) => {
            if (res.countryCode) {
              this.cookieService.set("geo", JSON.stringify(res), 1000);
              this.userCountry = res.countryCode;
              this.setDefaultSettings();
              resolve(true);
            }
          })
          .catch();
      } else {
        this.setDefaultSettings();
        resolve(true);
      }
    });
  }

  setDefaultSettings() {
    const geoData = JSON.parse(this.cookieService.get('geo'));
    this.userCountry = geoData.countryCode;
    if (!['EU'].includes(geoData.continentCode)) {
      this.primaryCurrency = 'USD';
    }
    if (['NA'].includes(geoData.continentCode)) {
      this.primaryServer = 1;
    }
    if (['OCE'].includes(geoData.continentCode)) {
      this.primaryServer = 9;
    }
    
    if (['DE','FR','ES','IT','NL','GB','UK','PT','BE','LU','CH','AT','IE','NO','SE','DK','FI','IS','GL','AX','FO'].includes(geoData.countryCode)) {
      this.primaryServer = 2;
    }else if(['EU'].includes(geoData.continentCode)){
      this.primaryServer = 3;
    }

    if (geoData.countryCode === 'RU') {
      this.primaryServer = 5;
    }
    if (geoData.countryCode === 'BR') {
      this.primaryServer = 6;
    }
    if (geoData.countryCode === 'TR') {
      this.primaryServer = 4;
    }
    if (geoData.countryCode === 'JP') {
      this.primaryServer = 11;
    }
    if (geoData.countryCode === 'CN') {
      this.primaryServer = 14;
    }
    if (['MX','PA','CU','GT','HN','NI','CR','DO','BZ','SV'].includes(geoData.countryCode)) {
      this.primaryServer = 7;
    }
    if (['CL','AR','BO','UY','PY','PE','CO','VE','EC','GY','SR'].includes(geoData.countryCode)) {
      this.primaryServer = 8;
    }
  }

  firstAuth() {
    return new Promise((resolve) => {
      if (!localStorage.getItem(this.TOKEN_KEY)) {
        this.setData(false);
        resolve(false);
      } else {
        // (async () => {
        this.http
          .post<any>(this.BASE_URL + "/isAuthenticated", {
            access_token: localStorage.getItem(this.TOKEN_KEY)
          })
          .toPromise()
          .then((res) => {
            const body = res;
            if (body.online) this.online = body.online;
            if (body.token) {
              localStorage.setItem(this.TOKEN_KEY, body.token);
              this.setData(true);
              resolve(true);
            } else {
              this.logout();
              resolve(false);
            }
          })
          .catch();
        // })();
      }
    });
  }

  isAuthenticated() {
    return new Promise((resolve) => {
      if (!localStorage.getItem(this.TOKEN_KEY)) {
        this.setData(false);
        resolve(false);
      } else {
        this.setData(true);
        resolve(true);
      }
    });
  }

  setData(authenticated = false) {
    if (authenticated) {
      const data = <any>jwt_decode(localStorage.getItem(this.TOKEN_KEY));
      if (data.status === 0) {
        this.logout();
      }
      this.role = data.role;
      this.email = data.email;
      this.user_id = data.user;
      this.employee = data.employee;
      this.name = data.name;
      this.sanitized_name = data.sanitized_name;
      this.image = data.image;
      this.authenticated = true;
    } else {
      this.role = 3;
      this.user_id = 0;
      this.authenticated = false;
      this.email = "";
      this.name = "";
      this.sanitized_name = "";
      this.image = null;
      this.loyaltyRank = null;
      this.baseCashback = 1;
    }
  }

  get isAdmin() {
    if (this.role === 1) {
      return true;
    } else {
      return false;
    }
  }

  get isBooster() {
    if (this.role === 2) {
      return true;
    } else {
      return false;
    }
  }

  get isModerator() {
    if (this.role === 4) {
      return true;
    } else {
      return false;
    }
  }

  canViewOrder(id) {
    return new Promise((resolve) => {
      if (this.role !== 1) {
        this.http
          .post(this.BASE_URL + "/canViewOrder", {
            access_token: localStorage.getItem(this.TOKEN_KEY),
          })
          .subscribe(
            (res) => {
              if (res) {
                resolve(res);
              }
            },
            (err) => {
              resolve(false);
            }
          );
      } else {
        resolve(true);
      }
    });
  }

  login(loginData) {
    return new Promise((resolve) => {
      this.http.post(this.BASE_URL + "/login", loginData).subscribe(
        (res) => {
          resolve(this.authenticate(res));
        },
        (err) => {
          resolve(false);
        }
      );
    });
  }

  googleAuth(token) {
    return new Promise((resolve) => {
      this.http
        .post(this.BASE_URL + "/googleAuth", { googleToken: token })
        .subscribe(
          (res) => {
            resolve(this.authenticate(res));
          },
          (err) => {
            resolve(false);
          }
        );
    });
  }

  facebookAuth(token, email) {
    return new Promise((resolve) => {
      this.http
        .post(this.BASE_URL + "/facebookAuth", {
          facebookToken: token,
          email: email,
        })
        .subscribe(
          (res) => {
            resolve(this.authenticate(res));
          },
          (err) => {
            resolve(false);
          }
        );
    });
  }

  discordAuth(token, url="boostroyal.com", type="login") {
    return new Promise((resolve) => {
      this.http
        .post(this.BASE_URL + "/discordAuth/"+type+"/"+url, {
          token: token
        })
        .subscribe(
          (res) => {
            resolve(this.authenticate(res));
          },
          (err) => {
            resolve(false);
          }
        );
    });
  }

  logout() {
    localStorage.removeItem(this.TOKEN_KEY);
    this.setData(false);
  }

  authenticate(res) {
    return new Promise((resolve) => {
      const authResponse = res;

      if (!authResponse.token) {
        return;
      }
      localStorage.setItem(this.TOKEN_KEY, authResponse.token);

      this.setData(true);
      this.getUserInfo();
      resolve(true);
    });
  }

  getUserInfo() {
    return new Promise((resolve) => {
      this.http
        .post<any>(environment.apiServer + "/user/getUserInfo", {
          access_token: localStorage.getItem("token"),
        })
        .subscribe((res) => {
          if (res) {
            this.loyaltyRank = res.name;
            this.baseCashback = res.discount;
            this.coinBalance = res.balance;
            this.premiumUser = res.premiumUser;
          }
          resolve(true);
        });
    });
  }

  getLoyaltyDiscount() {
    return new Promise((resolve) => {
      if (this.loyaltyRank === null) {
        this.http
          .post<any>(environment.apiServer + "/user/getLoyaltyRank", {
            access_token: localStorage.getItem("token"),
          })
          .subscribe((res) => {
            if (res) {
              this.loyaltyRank = res.name;
              this.baseCashback = res.discount;
            }
            resolve(true);
          });
      } else {
        resolve(true);
      }
    });
  }

  getCoinBalance() {
    return new Promise((resolve) => {
      this.http
        .post<any>(environment.apiServer + "/boostcoin/getBalance", {
          access_token: localStorage.getItem("token"),
          user_id: this.user_id,
        })
        .subscribe((res) => {
          if (res) {
            this.coinBalance = res;
          }
          resolve(true);
        });
    });
  }
}
