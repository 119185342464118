import { Component, ComponentFactoryResolver, OnInit, Type, ViewChild, ViewContainerRef } from "@angular/core";
import { Router } from "@angular/router";
import { CookieService } from "ngx-cookie-service";
import { NgxSmartModalService } from 'ngx-smart-modal';
import { AuthService } from "../../service/auth.service";
declare const fbq;
declare const gtag;

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
})
export class FooterComponent implements OnInit {
  myComponent?: Type<any>;
  loaded = false;

  public cookieNotice = false;
  public year = new Date().getFullYear();

  constructor(public modalService: NgxSmartModalService, private router: Router, public auth: AuthService, private cookieService: CookieService) { }

  ngOnInit(): void {
    this.checkIfNewsletter();
    setTimeout(() => {
      if (!this.cookieService.check("gdpr_cookie")) {
        this.cookieNotice = true;
      }
    }, 10000);

    /*this.auth.getGeoInfo().then(rs => {
      if (this.cookieService.get("gdpr_cookie") || JSON.parse(this.cookieService.get("geo")).continentCode !== 'EU') {
        fbq('consent', 'grant');
        gtag('consent', 'update', {
          'ad_storage': 'granted',
          'analytics_storage': 'granted'
        });
        this.loadScripts();
      }
    });*/
  }

  loadNewsletter() {
    if (this.loaded) {
      this.modalService.open("newsletterModal");
    } else {
      import("../../components/newsletter/newsletter.module")
        .then((mod) => mod.NewsletterModule)
        .then((lazyModule) => {
          this.myComponent = lazyModule.components["lazy"];
          this.loaded = true;
        });
    }
  }

  checkIfNewsletter() {
    if (["/newsletter"].includes(
      this.router.url.split("?")[0]
    ) ||
      ["/newsletter"].includes(
        window.location.pathname.split("?")[0]
      )
    ) {
      this.loadNewsletter();
    }
  }

  acceptCookieNotice() {
    this.cookieService.set('gdpr_cookie', 'true', 1000);
    this.cookieNotice = false;
    /*fbq('consent', 'grant');
    gtag('consent', 'update', {
      'ad_storage': 'granted',
      'analytics_storage': 'granted'
    });*/
  }
}
