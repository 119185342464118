import { Component, Inject, LOCALE_ID, OnInit } from "@angular/core";
import { LinkService } from '../../../../service/link.service';

@Component({
  selector: "app-language-selector",
  templateUrl: "./language-selector.component.html",
  styleUrls: ["./language-selector.component.scss"]
})
export class LanguageSelectorComponent implements OnInit {
  public langSelectToggle = false;
  public language = {
    language: "Global",
    abbrevation: "en",
    lang: "en",
    flag: "united-states-of-america",
  };
  public languages = [
    {
      language: "Global",
      languages: "English",
      abbrevation: "en",
      lang: "en",
      flag: "united-states-of-america",
      link: "https://boostroyal.com",
    },
    {
      language: "France",
      languages: "Français",
      abbrevation: "fr",
      lang: "fr",
      flag: "france",
      link: "https://boostroyal.fr",
    },
    {
      language: "Germany",
      languages: "Deutsch",
      abbrevation: "de",
      lang: "de",
      flag: "germany",
      link: "https://boostroyal.de",
    },
    {
      language: "Spain",
      languages: "Español",
      abbrevation: "es",
      lang: "es",
      flag: "spain",
      link: "https://eloboostroyal.es",
    },
    {
      language: "Portugal",
      languages: "Português",
      abbrevation: "pt",
      lang: "pt",
      flag: "portugal",
      link: "https://boostroyal.pt",
    },
    {
      language: "Netherlands",
      languages: "Nederlands",
      abbrevation: "nl",
      lang: "nl",
      flag: "netherlands",
      link: "https://boostroyal.nl",
    },
    {
      language: "Italy",
      languages: "Italiano",
      abbrevation: "it",
      lang: "it",
      flag: "italy",
      link: "https://eloboostroyal.it",
    },
    {
      language: "Sweden",
      languages: "Svenska",
      abbrevation: "sv",
      lang: "sv",
      flag: "sweden",
      link: "https://boostroyal.se",
    },
    {
      language: "Norway",
      languages: "Norsk",
      abbrevation: "nn",
      lang: "nn",
      flag: "norway",
      link: "https://boostroyal.no",
    },
    {
      language: "Denmark",
      languages: "Dansk",
      abbrevation: "da",
      lang: "da",
      flag: "denmark",
      link: "https://boostroyal.co.dk",
    },
    {
      language: "China",
      languages: "中国人",
      abbrevation: "zh",
      lang: "zh",
      flag: "china",
      link: "https://boostroyal.cn",
    },
    {
      language: "Japan",
      languages: "日本語",
      abbrevation: "ja",
      lang: "ja",
      flag: "japan",
      link: "https://boostroyal.jp",
    },
    {
      language: "United Arab Emirates",
      languages: "عربى",
      abbrevation: "ar",
      lang: "ar",
      flag: "united-arab-emirates",
      link: "https://boostroyal.ae",
    },
    {
      language: "United Kingdom",
      languages: "English",
      abbrevation: "en-GB",
      lang: "en",
      flag: "united-kingdom",
      link: "https://boostroyal.co.uk",
    },
    {
      language: "Austria",
      languages: "Deutsch",
      abbrevation: "de-AT",
      lang: "de",
      flag: "austria",
      link: "https://boostroyal.at",
    },
    {
      language: "Switzerland",
      languages: "Deutsch",
      abbrevation: "de-CH",
      lang: "de",
      flag: "switzerland",
      link: "https://boostroyal.ch",
    },
  ];

  constructor(public linkService: LinkService,
    @Inject(LOCALE_ID) public locale: string) { }

  ngOnInit(): void {
    this.languages.forEach((element) => {
      if (element.abbrevation === this.locale) {
        this.language = element;
      }
    });
  }

  public clickLangSelector() {
    this.langSelectToggle = !this.langSelectToggle;
  }
}
