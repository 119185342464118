import { Injectable, Inject, PLATFORM_ID } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { AuthService } from "../../service/auth.service";
import { isPlatformBrowser } from "@angular/common";

@Injectable()
export class LoyaltyGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  canActivate(t) {
    if (isPlatformBrowser(this.platformId)) {
      return this.authService.getLoyaltyDiscount().then(function (res) {
        return true;
      });
    } else {
      return true;
    }
  }
}
