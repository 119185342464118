import { LOCALE_ID, NgModule } from "@angular/core";
import {
  BrowserModule,
} from "@angular/platform-browser";
import {
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from "@angular/common/http";
import { AppComponent } from "./app.component";
import { WebService } from "./service/web.service";
import { AuthService } from "./service/auth.service";
import { NotifierModule } from "angular-notifier";
import { AuthGuard } from "./auth/auth.guard";
import { AppRouting } from "./routes.module";
import { SharedService } from "./service/shared.service";
import { environment } from "../environments/environment";
import { LinkService } from "./service/link.service";
import { Angulartics2Module } from "angulartics2";
import { ViewportScroller } from "@angular/common";
import { LazyLoadImageModule } from "ng-lazyload-image";
import { LoyaltyGuard } from "./pages/loyalty-program/loyalty.guard";
import { NgxSmartModalModule } from "ngx-smart-modal";
import { LayoutModule } from "@angular/cdk/layout";
import { FormsModule } from "@angular/forms";
import { HttpErrorInterceptor } from "./http-error.interceptor";
import {
  SocialLoginModule,
  FacebookLoginProvider,
  GoogleLoginProvider,
  AmazonLoginProvider,
  MicrosoftLoginProvider
} from "angularx-social-login";
import { HeaderComponent } from "./layout/header/header.component";
import { FooterComponent } from "./layout/footer/footer.component";
import { LanguageSelectorComponent } from "./layout/header/components/language-selector/language-selector.component";
import { MembersAreaComponent } from "./layout/header/components/members-area/members-area.component";
import { DiscountLineComponent } from './layout/header/components/discount-line/discount-line.component';
import { ScullyLibModule } from '@scullyio/ng-lib';
import { Router, Scroll } from '@angular/router';
import { filter } from 'rxjs/operators';
import { SimplebarAngularModule } from 'simplebar-angular';
import { LoadingModule } from "./components/loading/loading.module";
import { CookieService } from "ngx-cookie-service";
import { LiveChatComponent } from "./components/live-chat/live-chat.component";
import { CurrencySelectorComponent } from "./layout/footer/components/currency-selector/currency-selector.component";
import { PayOrderGuard } from "./checkout/checkout/pay/payOrder.guard";
import { RecaptchaLoaderService } from "ng-recaptcha";

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    LanguageSelectorComponent,
    MembersAreaComponent,
    DiscountLineComponent,
    LiveChatComponent,
    CurrencySelectorComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: "br-frontend-new" }),
    HttpClientModule,
    NotifierModule.withConfig({
      position: {
        horizontal: {
          position: "right",
          distance: 12,
        },
        vertical: {
          position: "top",
          distance: 50,
          gap: 10,
        },
      },
      theme: "material",
    }),
    AppRouting,
    FormsModule,
    SocialLoginModule,
    NgxSmartModalModule.forRoot(),
    Angulartics2Module.forRoot({
      gst: {
        trackingIds: ["G-NKQDD22ECK", "AW-868101441"],
      },
      pageTracking: {
        autoTrackVirtualPages: true,
      },
    }),
    LazyLoadImageModule,
    LayoutModule,
    ScullyLibModule,
    SimplebarAngularModule,
    LoadingModule
  ],
  bootstrap: [AppComponent],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    WebService,
    LoyaltyGuard,
    SharedService,
    AuthService,
    LinkService,
    CookieService,
    PayOrderGuard,
    RecaptchaLoaderService,
    {
      provide: "SocialAuthServiceConfig",
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider("249303525693575"),
            lazyLoad: true,
          },
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              "1017005086890-5o0uaff4li2q35d78fo55q6ttp7dubci.apps.googleusercontent.com"
            ),
            lazyLoad: true,
          }/*,
          {
            id: AmazonLoginProvider.PROVIDER_ID,
            provider: new AmazonLoginProvider(
              'amzn1.application-oa2-client.d8f451d9546e4867b6b579a3d342ff92'
            ),
            lazyLoad: true,
          },
          {
            id: MicrosoftLoginProvider.PROVIDER_ID,
            provider: new MicrosoftLoginProvider('d3456787-08e3-41bf-9c3d-42e1d0a51abc'),
            lazyLoad: true,
          }*/
        ],
      },
    }
  ],
})
export class AppModule {
  constructor(router: Router, viewportScroller: ViewportScroller
  ) {
    router.events.pipe(
      filter((e): e is Scroll => e instanceof Scroll)
    ).subscribe(e => {
      if (e.position) {
        // backward navigation
        setTimeout(() => { viewportScroller.scrollToPosition([e.position[0], e.position[1] - 125]); }, 0);
      } else if (e.anchor) {
        // anchor navigation
        setTimeout(() => { viewportScroller.scrollToAnchor(e.anchor); }, 0);
      } else {
        // forward navigation
        setTimeout(() => { viewportScroller.scrollToPosition([0, 0]); }, 0);
      }
    });
  }
}
