import { Component, OnInit } from "@angular/core";
import { AuthService } from "../../../../service/auth.service";

@Component({
  selector: "app-currency-selector",
  templateUrl: "./currency-selector.component.html",
  styleUrls: ["./currency-selector.component.scss"]
})
export class CurrencySelectorComponent implements OnInit {
  public currencies = ["EUR", "USD"];

  constructor(public auth: AuthService) { }

  ngOnInit(): void {
  }
}
