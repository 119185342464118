export const environment = {
  production: false,
  apiServer: "https://beta-backend.boostroyal.com",
  socketServer: "https://ws.boostroyal.com",
  dashboard: "https://boostroyal-dashboard.pages.dev",
  frontend: "https://br-dev.pages.dev",
  cdn: "https://d1dou5kerfs1ji.cloudfront.net",
  onesignal: "807d737b-14d1-41b4-b553-c1ca960c84f0",

  stripeKey: "pk_test_eI5W79SZGgNuzsDV3yZKLrHc0039hhRENI",
  stripe1Key: "pk_test_51JO3PsBDR3EhzE1NK05KF4YMIVXDOP5TdkhmcmsQXdTzBIZUQKFsFAQnPpehSUkt2a9JFx47MapKkg6eqZXT6YvR00OToNvgKv"
};
