import {
  Component,
  OnInit,
  PLATFORM_ID,
  Inject,
  Injector,
  Renderer2,
  ElementRef,
  AfterViewInit,
  AfterContentInit,
  ViewChild,
  LOCALE_ID,
} from "@angular/core";
import {
  Router,
  NavigationEnd,
  RouterOutlet,
  NavigationStart,
  NavigationCancel,
  ActivatedRoute,
  RoutesRecognized,
} from "@angular/router";
import { isPlatformBrowser, isPlatformServer } from "@angular/common";
import { SharedService } from "./service/shared.service";
import { environment } from "../environments/environment";
import { NgxSmartModalService, NgxSmartModalComponent } from "ngx-smart-modal";
import { AuthService } from "./service/auth.service";
import { Angulartics2GoogleGlobalSiteTag } from "angulartics2/gst";
import { Angulartics2Facebook } from "angulartics2/facebook";
import { Angulartics2 } from "angulartics2";
//import { SwUpdate } from "@angular/service-worker";
import { interval } from "rxjs";
declare var window: any;

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent implements OnInit, AfterContentInit {
  isBrowser = false;
  chatLoader = true;
  public globals = environment;
  loading;

  firstLoad = true;
  trackInterval;
  intervalTime = 0;

  discountLine = true;

  updateSubscription;

  public tags = [
    { abbrevation: "en", tag: "en" },
    { abbrevation: "uk", tag: "en-GB" },
    { abbrevation: "fr", tag: "fr" },
    { abbrevation: "de", tag: "de" },
    { abbrevation: "ch", tag: "de-CH" },
    { abbrevation: "at", tag: "de-AT" },
    { abbrevation: "nl", tag: "nl" },
    { abbrevation: "es", tag: "es" },
    { abbrevation: "it", tag: "it" },
    { abbrevation: "se", tag: "se" },
    { abbrevation: "no", tag: "no" },
    { abbrevation: "dk", tag: "da" },
    { abbrevation: "cn", tag: "zh-CN" },
    { abbrevation: "ar", tag: "ar" },
    { abbrevation: "br", tag: "pt" },
    { abbrevation: "jp", tag: "ja" },
  ];

  constructor(
    angulartics: Angulartics2GoogleGlobalSiteTag,
    angularticsFB: Angulartics2Facebook,
    public auth: AuthService,
    public modalService: NgxSmartModalService,
    private router: Router,
    private route: ActivatedRoute,
    public sharedService: SharedService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private injector: Injector,
    element: ElementRef,
    renderer: Renderer2,
    @Inject(LOCALE_ID) public locale: string,
    private angulartics2: Angulartics2,
    //private updates: SwUpdate
  ) {
    angulartics.startTracking();
    angularticsFB.startTracking();
    if (isPlatformBrowser(this.platformId)) {
      renderer.setAttribute(
        element.nativeElement.parentElement.parentElement,
        "lang",
        this.locale
      );
      renderer.setAttribute(
        element.nativeElement.parentElement,
        "class",
        element.nativeElement.parentElement.getAttribute('class')+ " " +this.locale
      );
      if (this.locale === "ar") {
        renderer.setAttribute(
          element.nativeElement.parentElement.parentElement,
          "dir",
          "rtl"
        );
      }
    }

    if (isPlatformBrowser(this.platformId)) {
      if (sessionStorage.getItem("message") !== null) {
        alert(sessionStorage.getItem("message"));
        sessionStorage.removeItem("message");
      }
    }

    const _this = this;
    this.trackInterval = setInterval(function () {
      _this.intervalTime += 40;
      _this.angulartics2.eventTrack.next({
        action: "Time spent on page - " + _this.intervalTime,
      });

      if (_this.intervalTime === 240) {
        clearInterval(_this.trackInterval);
      }
    }, 40000);
  }

  getAnimationData(routerOutlet: RouterOutlet) {
    const routeData = routerOutlet.activatedRouteData["animation"];
    return routeData ? routeData : "rootPage";
  }

  ngOnInit() {
    this.isBrowser = isPlatformBrowser(this.platformId);

    if (isPlatformBrowser(this.platformId)) {
      setTimeout(() => {
        if (this.route.snapshot.queryParamMap.get("partner")) {
          sessionStorage.setItem(
            "partner",
            this.route.snapshot.queryParamMap.get("partner")
          );
        }
      });
    }
  }

  ngAfterContentInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        if (!this.firstLoad) {
          this.loading = true;
        }
      } else if (
        event instanceof NavigationEnd ||
        event instanceof NavigationCancel
      ) {
        this.loading = false;

        if (!this.firstLoad) {
          window.uetq = window.uetq || [];
          window.uetq.push('event', 'page_view', { 'page_path': event.url });
          window.ttq.track('ViewContent', {content_type: 'product', content_id: event.url});
        } else {
          this.firstLoad = false;
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.modalService.removeModal("loginModal");
    if (this.trackInterval) {
      clearInterval(this.trackInterval);
    }
  }

  /*public checkForUpdates(): void {
    this.updateSubscription = this.updates.available.subscribe(event => this.promptUser());

    if (this.updates.isEnabled) {
      // Required to enable updates on Windows and ios.
      this.updates.activateUpdate();

      interval(60 * 60 * 1000).subscribe(() => {
        this.updates.checkForUpdate().then(() => {
          // console.log('checking for updates');
        });
      });

    }
  }
  promptUser(): void {
    this.updates.activateUpdate().then(() => {
      window.location.reload();
    });
  }*/
}
