<div>
  <a *ngIf="!auth.authenticated" class="btn gradient" href="javascript:void(0)" (click)="login()">
    <span i18n="@@header.login">Client Area</span>
  </a>
  <div *ngIf="auth.authenticated" class="relative group" (mouseenter)="loginDropdownToggle = !loginDropdownToggle"
    (mouseleave)="loginDropdownToggle = !loginDropdownToggle">
    <a class="btn gradient flex items-center gap-1 group-hover:rounded-b-none lg:group-hover:rounded-full" href="javascript:void(0)" (click)="login()">
      <span class="lg:hidden">{{ auth.name }}</span>
      <span class="hidden lg:block">Client Area</span>
      <svg xmlns="http://www.w3.org/2000/svg" class="lg:hidden" width="9.828" height="5.914" viewBox="0 0 9.828 5.914">
        <path id="Path_3051" data-name="Path 3051" d="M12,5l3.5,3.5L12,12"
          transform="translate(13.414 -10.586) rotate(90)" fill="none" stroke="#fff" stroke-linecap="round"
          stroke-linejoin="round" stroke-width="2" />
      </svg>
    </a>
    <div class="absolute z-10 lg:hidden w-full bg-box-gradient bg-deep-purple-3 rounded-b-lg p-4" *ngIf="loginDropdownToggle">
      <div class="flex gap-2 items-center justify-center">
        <img width="36" height="36" [src]="globals.cdn + '/uploads/users/' + auth.image"
          onError="this.src = 'https://d1dou5kerfs1ji.cloudfront.net/uploads/users/default.jpg';" />
        <div>
          <div class="text-sm">{{ auth.name }}</div>
          <div class="opacity-80 font-light text-xs" *ngIf="auth.loyaltyRank">
            {{ auth.newNames[auth.loyaltyRank] }}
          </div>
        </div>
      </div>
      <div class="text-yellow flex gap-1 items-center justify-center mt-2 text-sm text-center" *ngIf="auth.coinBalance">
        <img [lazyLoad]="'/assets/images/coins.svg'">
        {{ auth.coinBalance | number }}
      </div>
      <div class="w-full h-0.5 bg-white/5 my-4"></div>
      <ul class="font-bold flex flex-col gap-4">
        <li class="cursor-pointer duration-300 hover:text-yellow" (click)="goToDashboard()">
          <span>My orders</span>
        </li>
        <li class="cursor-pointer duration-300 hover:text-yellow" (click)="auth.logout()">
          <span>Logout</span>
        </li>
      </ul>
    </div>
  </div>
</div>