import { BreakpointObserver, BreakpointState } from "@angular/cdk/layout";
import { isPlatformBrowser } from "@angular/common";
import {
  Component,
  EventEmitter,
  Inject,
  LOCALE_ID,
  OnInit,
  Output,
  PLATFORM_ID,
  Type,
  ViewChild,
} from "@angular/core";
import {
  ActivatedRoute,
  NavigationCancel,
  NavigationEnd,
  NavigationStart,
  Router,
} from "@angular/router";
import { AuthService } from "../../service/auth.service";
import { environment } from "../../../environments/environment";
import { NgxSmartModalService } from "ngx-smart-modal";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"]
})
export class HeaderComponent implements OnInit {
  //@ViewChild(LiveChatComponent) liveChat: LiveChatComponent;
  @Output() onDiscountLine = new EventEmitter();

  private globals = environment;

  myComponent?: Type<any>;
  loaded = false;
  myVerifyComponent?: Type<any>;
  verifyLoaded = false;

  public page = "false";
  public deviceNav = "full";

  menus = [
    /* {
      name: $localize`:@@header.menu.prices:Prices`,
      title: 'BoostRoyal Prices',
      link: '/lol-boosting'
    },
    {
      name: $localize`:@@header.menu.boosters:Boosters`,
      title: $localize`:@@header.menu.boosters:Boosters`,
      link: $localize`:@@header.menu.boosters-link:/boosters`,
      type: 'Boosters'
    }, */
    {
      name: $localize`:@@header.menu.contact:Support`,
      title: $localize`:@@header.menu.contact:Support`,
      link: $localize`:@@header.menu.contactUs-link:/contact`,
    },
    {
      name: 'Premium',
      title: 'BoostRoyal Premium',
      link: '/boostroyal-premium',
      mobile: true
    },
    {
      name: $localize`:@@header.loyalty:Loyalty`,
      title: 'BoostRoyal Loyalty Program',
      link: $localize`:@@header.loyalty-link:/loyalty-program`,
      mobile: true
    },
    {
      name: $localize`:@@header.mobilePay:Mobile Pay`,
      title: 'Pay by mobile',
      link: $localize`:@@header.mobilePay-link:/pay-with-mobile`,
      mobile: true
    }
  ];

  constructor(
    private modalService: NgxSmartModalService,
    private router: Router,
    private route: ActivatedRoute,
    @Inject(PLATFORM_ID) private platformId: Object,
    public breakpointObserver: BreakpointObserver,
    public auth: AuthService,
    @Inject(LOCALE_ID) public locale: string
  ) { }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.breakpointObserver
        .observe(["(min-width: 1280px)"])
        .subscribe((state: BreakpointState) => {
          if (state.matches) {
            this.deviceNav = "full";
          } else {
            this.deviceNav = "out";
          }
        });

      this.checkCurrentMenu();
      this.checkIfLogin();
      this.checkIfConfirm();
      this.router.events.subscribe((evt) => {
        if (!(evt instanceof NavigationEnd)) {
          return;
        }
        if (this.breakpointObserver.isMatched("(max-width: 1279px)")) {
          this.deviceNav = "out";
        }
      });
    }
  }

  public changePage(page) {
    this.page = page;
  }
  public changeNav() {
    this.deviceNav = this.deviceNav === "out" ? "in" : "out";
  }

  backDrop() {
    if (this.deviceNav === "in") {
      this.deviceNav = "out";
    }
  }

  checkCurrentMenu() {
    if (["/", "/login", "/relogin", "/logout", "/newsletter", "/verifyaccount"].includes(
      this.router.url.split("?")[0]
    ) &&
      ["/", "/login", "/relogin", "/logout", "/newsletter", "/verifyaccount"].includes(
        window.location.pathname.split("?")[0]
      )
    ) {
      this.page = "menu1-link";
    } else {
      this.page = "";
    }
  }
  checkIfLogin() {
    if (["/login", "/relogin", "/logout"].includes(
      this.router.url.split("?")[0]
    ) ||
      ["/login", "/relogin", "/logout"].includes(
        window.location.pathname.split("?")[0]
      )
    ) {
      this.login();
    }
  }

  login() {
    if (this.loaded) {
      try {
        this.modalService.open("loginModal");
      } catch (error) {
        window.open(this.globals.frontend + "/login", "_self");
      }
    } else {
      import("../../auth/login/login.module")
        .then((mod) => mod.LoginModule)
        .then((lazyModule) => {
          this.myComponent = lazyModule.components["lazy"];
          this.loaded = true;
        });
    }
  }

  checkIfConfirm() {
    if ((window.location.pathname.split("?")[0].includes('/verifyaccount') || window.location.pathname.split("?")[0].includes('/passwordrecovery')) && !this.verifyLoaded) {
      import("../../auth/confirmaccount/confirmaccount.module")
      .then((mod) => mod.ConfirmaccountModule)
      .then((lazyModule) => {
        this.myVerifyComponent = lazyModule.components["lazy"];
        this.verifyLoaded = true;
      });
    }
  }

  ngAfterContentInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
      } else if (
        event instanceof NavigationEnd ||
        event instanceof NavigationCancel
      ) {
        if (isPlatformBrowser(this.platformId)) {
          this.checkCurrentMenu();
          this.checkIfLogin();
          this.checkIfConfirm();
        }
      }
    });
  }
}
