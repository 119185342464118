<header class="absolute top-0 z-40 w-full direct">
  <!-- [ngClass]="{'homeHead' : page === 'false' || page === 'menu1-link'}"-->
  <div class="h-11 text-base font-bold">
    <div class="container flex justify-between items-center leading-11 h-full">
      <div>
        <a class="flex items-center gap-2 ltr" routerLink="/boosters" i18n-routerLink="header.menu.boosters-link" *ngIf="auth.userCountry !== 'HU' && (!rlaAccount1.isActive && !rlaAccount2.isActive && !rlaAccount3.isActive && !checkout.isActive)">
          <span class="flex relative">
            <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-green opacity-75"></span>
            <span class="relative inline-flex rounded-full h-3 w-3 bg-green"></span>
          </span>
          <div class="flex items-center gap-1">
            <div *ngIf="auth.online">
              {{auth.online}}
            </div>
            <div>Boosters</div>
          </div>
        </a>
      </div>
      <div class="flex justify-end gap-12 xl:gap-6">
        <div class="hidden xl:block">
          <a class="flex items-center gap-2" (click)="login()" title="Client Area">
            <img src="/assets/icons/members-area.svg" alt="BoostRoyal Client Area" height="14">
            <span class="duration-500 hover:text-yellow">Client Area</span>
          </a>
        </div>
        <div class="flex items-center xl:hidden" *ngIf="auth.userCountry !== 'HU'">
          <a class="flex items-center gap-2" routerLink="/boostroyal-premium" title="BoostRoyal Premium">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M3.14315 5.72549e-08C2.98776 -5.83143e-05 2.83528 0.0445168 2.70201 0.128957C2.56875 0.213398 2.45971 0.334527 2.38658 0.479388L0.100842 5.00761C0.0227388 5.16233 -0.0111695 5.33769 0.00323467 5.51239C0.0176388 5.68709 0.0797358 5.85361 0.181986 5.99174L7.3249 15.6519C7.40507 15.7604 7.50756 15.848 7.6246 15.9083C7.74163 15.9687 7.87013 16 8.00034 16C8.13055 16 8.25905 15.9687 8.37608 15.9083C8.49312 15.848 8.59561 15.7604 8.67577 15.6519L15.8187 5.99174C15.9208 5.85349 15.9827 5.68691 15.9969 5.51221C16.0111 5.33752 15.977 5.16222 15.8987 5.00761L13.613 0.479388C13.5399 0.334707 13.4311 0.213696 13.298 0.129268C13.165 0.0448388 13.0127 0.000160433 12.8575 5.72549e-08H3.14315ZM2.134 4.8301L3.65744 1.81129H5.11689L4.40259 4.8301H2.134ZM2.83801 6.64139H4.6026L5.77861 10.6178L2.83801 6.64139ZM6.39804 6.64139H9.60264L8.00034 12.0583L6.39804 6.64139ZM11.3981 6.64139H13.1627L10.2221 10.6178L11.3992 6.64139H11.3981ZM13.8667 4.8301H11.5981L10.8838 1.81129H12.3432L13.8667 4.8301ZM9.83121 4.8301H6.16947L6.88376 1.81129H9.11692L9.83121 4.8301Z" fill="url(#paint0_linear_2_2797)"/>
              <defs>
              <linearGradient id="paint0_linear_2_2797" x1="3.6608" y1="1.2768" x2="12.3392" y2="14.7232" gradientUnits="userSpaceOnUse">
              <stop stop-color="#C9504E"/>
              <stop offset="0.3" stop-color="#D35E4F"/>
              <stop offset="0.86" stop-color="#EC8353"/>
              </linearGradient>
              </defs>
            </svg>              
            <span class="duration-500 hover:text-yellow">Premium</span>
          </a>
        </div>
        <div class="flex items-center xl:hidden">
          <a class="flex items-center gap-2" i18n-routerLink="@@header.loyalty-link" routerLink="/loyalty-program" title="BoostRoyal Loyalty Program">
            <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <mask id="path-1-inside-1_2_6609" fill="white">
              <path d="M17.4386 2.25787C16.6848 0.733516 14.5132 -0.513679 11.9872 0.212465C10.7801 0.555992 9.72702 1.29269 8.99988 2.30221C8.27273 1.29269 7.2196 0.555992 6.01256 0.212465C3.48094 -0.502593 1.315 0.733516 0.561134 2.25787C-0.496521 4.39195 -0.0577064 6.79211 1.86633 9.39182C3.37405 11.4261 5.52874 13.4882 8.6567 15.8828C8.75555 15.9587 8.87732 16 9.00269 16C9.12805 16 9.24982 15.9587 9.34868 15.8828C12.471 13.4937 14.6313 11.4483 16.1391 9.39182C18.0575 6.79211 18.4963 4.39195 17.4386 2.25787V2.25787Z"/>
              </mask>
              <path d="M17.4386 2.25787C16.6848 0.733516 14.5132 -0.513679 11.9872 0.212465C10.7801 0.555992 9.72702 1.29269 8.99988 2.30221C8.27273 1.29269 7.2196 0.555992 6.01256 0.212465C3.48094 -0.502593 1.315 0.733516 0.561134 2.25787C-0.496521 4.39195 -0.0577064 6.79211 1.86633 9.39182C3.37405 11.4261 5.52874 13.4882 8.6567 15.8828C8.75555 15.9587 8.87732 16 9.00269 16C9.12805 16 9.24982 15.9587 9.34868 15.8828C12.471 13.4937 14.6313 11.4483 16.1391 9.39182C18.0575 6.79211 18.4963 4.39195 17.4386 2.25787V2.25787Z" stroke="url(#paint0_linear_2_6609)" stroke-width="4" mask="url(#path-1-inside-1_2_6609)"/>
              <defs>
              <linearGradient id="paint0_linear_2_6609" x1="4.1184" y1="1.2768" x2="12.3399" y2="15.6076" gradientUnits="userSpaceOnUse">
              <stop stop-color="#C9504E"/>
              <stop offset="0.3" stop-color="#D35E4F"/>
              <stop offset="0.86" stop-color="#EC8353"/>
              </linearGradient>
              </defs>
            </svg>
            <span class="duration-500 hover:text-yellow" i18n="@@header.loyalty">Loyalty</span>
          </a>
        </div>
        <app-language-selector *ngIf="locale !== 'zh' && auth.userCountry !== 'HU'"></app-language-selector>
      </div>
    </div>
  </div>
  <div>
    <app-discount-line (onDiscount)="onDiscountLine.emit(true)"></app-discount-line>
    <nav class="font-bold h-28 z-40 relative xl:static">
      <div class="container flex items-center justify-between h-full">
        <a class="flex items-center gap-3" (click)="changePage('menu1-link')" href="/" routerLink="/" title="LoL ELO Boost">
          <img class="hover:animate-pulse" alt="LoL ELO Boost" width="50" height="50" title="LoL ELO Boost"
            src="/assets/images/new/logo-icon.svg?v=3?v=2" />
          <img class="mt-[6px]" alt="BoostRoyal" title="BoostRoyal"
            src="/assets/images/new/logo-text.svg?v=3" height="32"/>
        </a>

        <div class="hidden xl:block">
          <svg viewBox="0 0 100 80" width="40" height="40" class="cursor-pointer" (click)="changeNav()">
            <rect width="100" height="15" rx="8" fill="#ffffff"></rect>
            <rect y="30" width="100" height="15" rx="8" fill="#ffffff"></rect>
            <rect y="60" width="100" height="15" rx="8" fill="#ffffff"></rect>
          </svg>
        </div>

        <div class="flex gap-16 xl:flex-col xl:fixed xl:inset-0 z-[99999] xl:duration-300 xl:bg-deep-purple-1 xl:p-4 xl:items-center xl:justify-evenly xl:gap-10 {{deviceNav === 'in' ? 'opacity-100 visible xl:w-full' : 'xl:opacity-0 xl:invisible'}}">
          <ng-container *ngFor="let menu of menus;let i = index"> 
            <div *ngIf="(!rlaAccount1.isActive && !rlaAccount2.isActive && !rlaAccount3.isActive && auth.userCountry !== 'HU' && menu.type === 'Boosters') || !menu.type" class="text-lg flex items-center xl:text-3xl font-bold duration-300 hover:text-yellow {{menu.mobile ? 'hidden xl:block' : ''}} {{i === 0 && (rlaLOL0.isActive || rlaLOL1.isActive || rlaLOL2.isActive || rlaLOL3.isActive || rlaLOL4.isActive || rlaLOL5.isActive || rlaLOL6.isActive || rlaLOL7.isActive || rlaLOL8.isActive || rlaLOL9.isActive  || rlaLOR.isActive  || rlaTFT.isActive) ? 'text-yellow' : ''}}">
              <a routerLinkActive='text-yellow' [title]="menu.title" [routerLink]="menu.link">{{menu.name}}</a>
            </div>
          </ng-container>

          <div class="hidden xl:block absolute cursor-pointer top-3 right-3" (click)="backDrop()">
            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 512 512"
              xml:space="preserve" width="20px" height="20px">
              <g>
                <path
                  d="M505.943,6.058c-8.077-8.077-21.172-8.077-29.249,0L6.058,476.693c-8.077,8.077-8.077,21.172,0,29.249    C10.096,509.982,15.39,512,20.683,512c5.293,0,10.586-2.019,14.625-6.059L505.943,35.306    C514.019,27.23,514.019,14.135,505.943,6.058z"
                  fill="currentColor"></path>
              </g>
              <g>
                <path
                  d="M505.942,476.694L35.306,6.059c-8.076-8.077-21.172-8.077-29.248,0c-8.077,8.076-8.077,21.171,0,29.248l470.636,470.636    c4.038,4.039,9.332,6.058,14.625,6.058c5.293,0,10.587-2.019,14.624-6.057C514.018,497.866,514.018,484.771,505.942,476.694z"
                  fill="currentColor"></path>
              </g>
            </svg>
          </div>
          <app-members-area></app-members-area>
        </div>
      </div>
    </nav>
  </div>
</header>

<div class="hidden">
  <a routerLinkActive #rlaLOL0="routerLinkActive" routerLink="/account-leveling"
  i18n-routerLink="@@products.leveling-link">
  </a>
  <a routerLinkActive #rlaLOL1="routerLinkActive" routerLink="/ranked-wins-boost"
    i18n-routerLink="@@products.winBoost-link">
  </a>
  <a routerLinkActive #rlaLOL2="routerLinkActive" routerLink="/duoqueue-boosting"
    i18n-routerLink="@@products.duoBoost-link">
  </a>
  <a routerLinkActive #rlaLOL3="routerLinkActive" routerLink="/placement-matches"
    i18n-routerLink="@@products.placementBoost-link">
  </a>
  <a routerLinkActive #rlaLOL4="routerLinkActive" routerLink="/champion-mastery"
    i18n-routerLink="@@products.champMastery-link">
  </a>
  <a routerLinkActive #rlaLOL5="routerLinkActive" routerLink="/clash-boosting"
    i18n-routerLink="@@products.clashBoost-link">
  </a>
  <a routerLinkActive #rlaLOL6="routerLinkActive" routerLink="/normal-matches"
    i18n-routerLink="@@products.normalBoost-link">
  </a>
  <a routerLinkActive #rlaLOL7="routerLinkActive" routerLink="/wild-rift-smurf">
  </a>
  <a routerLinkActive #rlaLOL8="routerLinkActive" routerLink="/promotion-boosting"
    i18n-routerLink="@@products.promoBoosting-link">
  </a>
  <a routerLinkActive #rlaLOL9="routerLinkActive" routerLink="/valorant-smurf">
  </a>
  <a routerLinkActive #rlaLOR="routerLinkActive" routerLink="/lor-boosting"
    i18n-routerLink="@@products.lorBoost-link">
  </a>
  <a routerLinkActive #rlaTFT="routerLinkActive" routerLink="/tft-ranked-boost"
    i18n-routerLink="@@products.tftBoost-link">
  </a>
  
  <a routerLinkActive #rlaAccount1="routerLinkActive" routerLink="/buy-league-of-legends-account"
    i18n-routerLink="@@products.accounts-link">
  </a>
  <a routerLinkActive #rlaAccount2="routerLinkActive" routerLink="/buy-smurf-account"
    i18n-routerLink="@@products.smurf-link">
  </a>
  <a routerLinkActive #rlaAccount3="routerLinkActive" routerLink="/buy-lol-account"
    i18n-routerLink="@@products.market-link">
  </a>
  <a routerLinkActive #checkout="routerLinkActive" routerLink="/checkout/payment">
  </a>
</div>

<div *ngIf="loaded">
  <ng-container *ngComponentOutlet="myComponent"></ng-container>
</div>
<div *ngIf="verifyLoaded">
  <ng-container *ngComponentOutlet="myVerifyComponent"></ng-container>
</div>
<div *ngIf="deviceNav === 'in'" class="fixed inset-0 bg-black bg-opacity-50 z-30" (click)="backDrop()"></div>