import { Component, OnInit } from "@angular/core";
import { SharedService } from "../../service/shared.service";

@Component({
  selector: "app-live-chat",
  templateUrl: "./live-chat.component.html",
  styleUrls: ["./live-chat.component.scss"],
})
export class LiveChatComponent implements OnInit {
  public loading = false;

  constructor(public sharedService: SharedService) { }

  ngOnInit(): void {
  }

  public loadChat() {
    this.sharedService.showLiveChat = false;
    this.loading = true;
    var isFound = false;
    var scripts = document.getElementsByTagName("script");
    for (var i = 0; i < scripts.length; ++i) {
      if (
        scripts[i].getAttribute("src") != null &&
        scripts[i].getAttribute("src").includes("tidio")
      ) {
        isFound = true;
      }
    }

    if (!isFound) {
      var dynamicScripts = [
        "//code.tidio.co/djyahlrjrtwe1rr3h9nziiyqarrpampi.js",
        "/assets/tidio.js?v=2",
      ];

      for (var i = 0; i < dynamicScripts.length; i++) {
        let node = document.createElement("script");
        node.src = dynamicScripts[i];
        node.type = "text/javascript";
        node.async = false;
        node.charset = "utf-8";
        document.getElementsByTagName("head")[0].appendChild(node);
      }
    } else {
      var dynamicScripts = ["/assets/tidio.js?v=2"];

      for (var i = 0; i < dynamicScripts.length; i++) {
        let node = document.createElement("script");
        node.src = dynamicScripts[i];
        node.type = "text/javascript";
        node.async = false;
        node.charset = "utf-8";
        document.getElementsByTagName("head")[0].appendChild(node);
      }
    }

  }
}
