<div class="language-selector cursor-pointer relative" (mouseenter)="clickLangSelector()" (mouseleave)="clickLangSelector()">
  <div class="flex items-center gap-2">
    <div>{{ language.abbrevation | uppercase }}</div>
    <svg width="14" height="7" viewBox="0 0 14 7" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 1L6.6 5.8L12.2 1" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>      
  </div>
  <div class="absolute z-50 rounded-lg bg-deep-purple-1 overflow-hidden flex flex-col w-80 right-0" [hidden]="!langSelectToggle" style="background: linear-gradient(180deg, #341C31 0%, #2F2032 100%);">
    <ngx-simplebar class="p-7" style="max-height: 500px" [options]="{autoHide: false}">
      <ng-container *ngFor="let lang of languages">
        <a class="flex items-center gap-4 py-3 px-4 hover:bg-opacity-10 hover:bg-white duration-200 rounded-lg" *ngIf="lang.abbrevation !== language.abbrevation" href="{{ lang.link }}{{ linkService.getRoute(lang.lang) }}">
          <img height="30" width="30" alt="Elo boost {{ lang.lang }}"
            [lazyLoad]="'/assets/images/flags-new/'+lang.flag+'.svg'" />
          <div>
            <span>{{ lang.language }}</span>
            <div class="font-thin">{{lang.languages}}</div>
          </div>
        </a>
      </ng-container>
    </ngx-simplebar>
  </div>
</div>