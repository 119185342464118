import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse,
  HttpClient,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { retry, catchError } from "rxjs/operators";
import { Injectable, Injector } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "./service/auth.service";
import { environment } from "../environments/environment";
import { WebService } from "./service/web.service";

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  auth: AuthService;
  env = environment;
  BASE_URL = environment.apiServer;
  constructor(
    private injector: Injector,
    private router: Router,
    private http: HttpClient
  ) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      retry(1),
      catchError((error: HttpErrorResponse) => {
        this.auth = this.auth || this.injector.get(AuthService);
        let errorMessage = "";
        if (error.error instanceof ErrorEvent) {
          // client-side error
          errorMessage = `Error: ${error.error.message}`;
          console.log(error.error);
          console.log(error);
          /*this.http
            .post<any>(this.BASE_URL + "/logError", {
              code: error.status,
              error: error.error.message,
              info: "Frontend Angular: " + window.location.href,
            })
            .subscribe((rs) => {
              console.log(rs);
            });*/
        } else {
          // server-side error
          errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
          if (error.status === 401 || error.status === 400) {
            this.auth.logout();
          } else {
            console.log(errorMessage);
            /*this.http
              .post<any>(this.BASE_URL + "/logError", {
                code: errorMessage,
                error: error.message,
                info:
                  this.auth.user_id +
                  " | " +
                  "Frontend Angular SSR: " +
                  window.location.href,
              })
              .subscribe((rs) => {
                console.log(rs);
              });*/
          }
        }
        return throwError(errorMessage);
      })
    );
  }
}
